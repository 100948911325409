import styled from "styled-components";

export const Container = styled.div`
  @media print {
    .btn {
      display: none;
    }
    width: 100%;
  }
`;
